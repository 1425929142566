/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function SEO({
  description, lang, creator, title, url, fbImg, twImg, card, meta,
}) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:image',
          content: fbImg,
        },
        {
          property: 'og:site_name',
          content: 'Debt.com',
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          name: 'twitter:card',
          content: card,
        },
        {
          name: 'twitter:site',
          content: '@debtcom',
        },
        {
          name: 'twitter:creator',
          content: creator,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'twitter:image',
          content: twImg,
        },
        {
          'http-equiv': 'cache-control',
          content: 'no-cache',
        },
        {
          'http-equiv': 'Pragma',
          content: 'no-cache',
        },
        {
          'http-equiv': 'Expires',
          content: '-1',
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  creator: '@debtcom',
  card: 'summary_large_image',
};

SEO.propTypes = {
  creator: PropTypes.string,
  url: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  fbImg: PropTypes.string.isRequired,
  twImg: PropTypes.string.isRequired,
  card: PropTypes.string,
};

export default SEO;
